.userInfo{
  display: flex;
  align-items: stretch;
  flex-shrink: 0;

  .user-name{
    margin-top: 0.6em;
    margin-left: 0.2em;
    margin-right: 1.4rem;
    font-size: 20px;
    color: #54657E;
  }
}
