//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base';
@import 'layout/layout';


.font-weight-semibold {
  font-weight: $font-weight-bold;
}

.font-weight-bold {
  font-weight: $font-weight-bolder;
}

.font-weight-extrabold {
  font-weight: $font-weight-extrabold;
}