.box{
  .container-1{
    vertical-align: middle;
    white-space: nowrap;
    position: relative;
    filter: drop-shadow(0 0.3rem 0.3rem grey);
  }

  .container-1 input#search{
    height: 40px;
    background: #EBFAFF;
    border: none;
    font-size: 10pt;
    float: right;
    color: #0075E2;
    padding-left: 22px;
    padding-right: 48px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 25px;
    -webkit-transition: background .55s ease;
    -moz-transition: background .55s ease;
    -ms-transition: background .55s ease;
    -o-transition: background .55s ease;
    transition: background .55s ease;
  }

  .container-1 input#search::-webkit-input-placeholder {
    color: #0075E2;
  }

  .container-1 input#search:-moz-placeholder { /* Firefox 18- */
    color: #65737e;
  }

  .container-1 input#search::-moz-placeholder {  /* Firefox 19+ */
    color: #65737e;
  }

  .container-1 input#search:-ms-input-placeholder {
    color: #65737e;
  }

  .container-1 .icon{
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 1;
    color: #4f5b66;
  }

  .container-1 input#search:hover, .container-1 input#search:focus, .container-1 input#search:active{
    outline:none;
    background: #EBFAFF;
  }
}

@include media-breakpoint-down(md) {
  div div .box .container-1 input#search {
    width: 98% !important;
  }

  .userInfo p {
    display: none;
  }

}

@include media-breakpoint-up(md) {
  div div .box .container-1 input#search {
    width: 50%;
  }
}
